/* eslint-disable indent */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
/* eslint-disable no-useless-concat */
/* eslint-disable no-useless-escape */
// import axios from 'axios'
import { paginateArray, sortCompare } from '@core/utils/filter'
import instance from '@/libs/axios.instance'
import authHeader from '@/libs/auth.header'

class TopWithdrawalService {

	getTopWithdrawalLists(queryParams) {
		const pars = {
			username: queryParams.username,
			userId: queryParams.userId,
			amount: queryParams.amount,
			countUser: queryParams.countUser,
			fromDate: queryParams.fromdate,
			toDate: queryParams.todate,
			pageNumber: queryParams.page,
		}
		return instance.get('/api/top-withdrawal', { headers: authHeader(), params: pars })
				.then(
					response => {
						const datas = {
							topWithdrawals: response.data.data,
							total: response.data.count,
							pageSize: queryParams.page == 1 ? response.data.data.length : 25
						}
						return datas
					}
				)
	}
}

export default new TopWithdrawalService()
