import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title, paginateArray, sortCompare } from '@core/utils/filter'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useTopWithdrawalsList() {
	// Use toast
	const toast = useToast()

	const refTopWithdrawalListTable = ref(null)

	// Table Handlers
	const tableColumns = [
		'index',
		{ key: 'username' },
		{ key: 'amount' },
		{ key: 'transaction', label: 'quantity transactions'}
	]
	const perPage = ref(20)
	const totalTopWithdrawals = ref(0)
	const currentPage = ref(1)
	const perPageOptions = [10, 25, 50, 100]
	const searchQuery = ref('')
	const sortBy = ref('id')
	const isSortDirDesc = ref(true)
	const usernameFilter = ref(null)
	const userIdFilter = ref(null)
	const amountFilter = ref(null)
	const countUserFilter = ref(null)
	const fromdateFilter = ref(null)
	const todateFilter = ref(null)

	const refetchData = () => {
		refTopWithdrawalListTable.value.refresh()
	}

	watch([currentPage, searchQuery, usernameFilter, userIdFilter, amountFilter, countUserFilter, fromdateFilter, todateFilter], () => {
		refetchData()
	})

	const fetchTopWithdrawals = (ctx, callback) => {
		store
			.dispatch('campaign-risk-management-top-withdrawals/fetchTopWithdrawals', {
				q: searchQuery.value,
				perPage: perPage.value,
				page: currentPage.value,
				sortBy: sortBy.value,
				sortDesc: isSortDirDesc.value,
				username: usernameFilter.value,
				amount: amountFilter.value,
				countUser: countUserFilter.value,
				userId: userIdFilter.value,
				fromdate: fromdateFilter.value,
				todate: todateFilter.value,
			})
			.then(response => {

				// console.log(response)
				const { topWithdrawals, total, pageSize } = response
				callback(topWithdrawals)
				totalTopWithdrawals.value = total
				perPage.value = pageSize
			})
			.catch(() => {
				toast({
					component: ToastificationContent,
					props: {
						title: 'Error fetching top withdrawals list',
						icon: 'AlertTriangleIcon',
						variant: 'danger',
					},
				})
			})
	}

	const dataMeta = computed(() => {
		const localItemsCount = refTopWithdrawalListTable.value ? refTopWithdrawalListTable.value.localItems.length : 0
		return {
			from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
			to: perPage.value * (currentPage.value - 1) + localItemsCount,
			of: totalTopWithdrawals.value,
		}
	})

	return {
		fetchTopWithdrawals,
		tableColumns,
		perPage,
		currentPage,
		totalTopWithdrawals,
		dataMeta,
		perPageOptions,
		searchQuery,
		sortBy,
		isSortDirDesc,
		refTopWithdrawalListTable,

		refetchData,

		// Extra Filters
		usernameFilter,
		userIdFilter,
		amountFilter,
		countUserFilter,
		fromdateFilter,
		todateFilter,
	}
}
